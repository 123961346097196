<template>
  <div class="home">

    <div style="width: 60%; margin: auto;">
      <el-row style="text-align: center;">
        <span >账号列表</span>
      </el-row>
      <el-row>
        <el-button size="small" @click="accountDialogVisible = true" type="primary">添加账号</el-button>
        <el-button size="small" @click="monitorVisiable = true; selectType = 1" type="success">开始监控</el-button>
        <el-button size="small" @click="$router.push('/about')" type="info">去航班页面</el-button>
        <el-button size="small" @click="monitorVisiable = true; selectType = 3" type="danger">重启服务</el-button>
        <el-button size="small" @click="monitorVisiable = true; selectType = 2" type="warning">切换票模式</el-button>
        <span>当前模式: {{ mode === 1 ? '普通票' : 'JK票' }}  </span>
      </el-row>
      <el-table
      :data="accountList"
      style="width: 100%">
      <el-table-column
        prop="phone"
        label="账号"
        >
      </el-table-column>
      <el-table-column
        prop="pwd"
        label="密码"
        >
      </el-table-column>
      <el-table-column
        prop="expire"
        label="过期时间">
      </el-table-column>
      <!-- <el-table-column
        prop="flag"
        label="服务器号">
      </el-table-column> -->
      <el-table-column
        prop="email"
        label="通知邮箱">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="ck"
        label="乘客">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="editAccount(scope.$index)"
            type="text"
            size="small">
            编辑
          </el-button>
          <el-button
            @click.native.prevent="deleteRow(scope.$index)"
            type="text"
            size="small">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog :visible.sync="accountDialogVisible" title="添加账号" width="50%">
      <el-form ref="accountForm" :model="accountForm" label-width="80px">
        <el-form-item label="手机号">
          <div>
            <el-input v-model="accountForm.phone" style="width: 50%;"></el-input>
            <el-button size="small" type="danger" @click="sendCode">发送验证码</el-button>
          </div>
        </el-form-item>
        <el-form-item label="验证码">
            <el-input v-model="accountForm.code" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sureAccount">确定</el-button>
          <el-button @click="accountDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="editAccountDialogVisible" title="编辑账号" width="50%">
      <el-form ref="editAccountData" :model="editAccountData" label-width="80px">
        <!-- <el-form-item label="服务器号">
          <el-input v-model="editAccountData.flag" style="width: 50%;"></el-input>
        </el-form-item> -->
        <el-form-item label="通知邮箱">
            <el-input v-model="editAccountData.email" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="乘客">
          <el-checkbox-group v-model="psCheckList">
            <el-checkbox :key="index" v-for="(item, index) in passerList" :label="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sureEditAccount">确定</el-button>
          <el-button @click="editAccountDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="monitorVisiable" :title="selectTitle" width="50%">
      <el-form ref="startMonitorData" :model="startMonitorData" label-width="80px">
        <el-form-item label="服务器">
          <el-select v-model="startMonitorData.flag" placeholder="请选择">
            <el-option
              v-for="item in fuwuqiList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sureDt">确定</el-button>
          <el-button @click="monitorVisiable = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
// @ is an alias to /src
import http from '../utils/http'
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      accountList: [],
      accountDialogVisible: false,
      editAccountDialogVisible: false,
      monitorVisiable: false,
      accountForm: {
        phone: '',
        code: '',
        guid: '',
        vid: ''
      },
      editAccountData: {},
      psCheckList: [],
      passerList: [],
      startMonitorData: {},
      mode: 0,
      fuwuqiList: [],
      selectTitle: '',
      selectType: 0
    }
  },
  mounted () {
    this.getAccoutList()
  },
  watch: {
    selectType (newVal, oldVal) {
      console.log('11111')
      // 数据发生变化时执行的操作
      if (newVal === 1) {
        this.selectTitle = '开始监控'
      } else if (newVal === 2) {
        this.selectTitle = '切换票模式'
      } else if (newVal === 3) {
        this.selectTitle = '重启服务'
      }
    }
  },
  methods: {
    sendCode () {
      if (this.accountForm.phone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      }
      http.post('/sendCode', {
        phone: this.accountForm.phone
      }, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.$message(res.msg)
          const data = res.data
          this.accountForm.vid = data.vid
          this.accountForm.guid = data.guid
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    sureAccount () {
      if (this.accountForm.phone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      }
      if (this.accountForm.code === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      }
      if (this.accountForm.guid === '') {
        this.$message({
          message: '请重新发送验证码',
          type: 'error'
        })
        return
      }
      http.post('/verifyAccount', this.accountForm, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.accountDialogVisible = false
          this.$message(res.msg)
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    getAccoutList () {
      http.post('/getAccoutList', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        this.getMode()
        if (res.status === 0) {
          this.accountList = res.data
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    getMode () {
      http.post('/getMode', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        this.getFwq()
        if (res.status === 0) {
          this.mode = res.data
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    getFwq () {
      http.post('/getFwq', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          for (let index = 0; index < res.datas.length; index++) {
            const element = res.datas[index]
            var obj = { label: element.ip, value: element.id }
            this.fuwuqiList.push(obj)
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    setMode () {
      http.post('/setMode', {
        mode: this.mode === 1 ? 2 : 1
      }, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.mode = res.data
          this.getMode()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    sureDt () {
      http.post('/selectFwqOper', {
        flag: this.startMonitorData.flag,
        type: this.selectType,
        mode: this.mode === 1 ? 2 : 1
      }, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.monitorVisiable = false
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    sureStart () {
      const obj = {
        flag: this.startMonitorData.flag
      }
      http.post('/start', obj, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.monitorVisiable = false
          this.$message(res.msg)
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    editAccount (a) {
      this.editAccountDialogVisible = true
      this.editAccountData = this.accountList[a]
      http.post('/getAllPassage', {}, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.passerList = res.data
          res.data.forEach(element => {
            if (element.accountId === this.editAccountData.id) {
              this.psCheckList.push(element.id)
            }
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    sureEditAccount () {
      // if (this.editAccountData.flag === '') {
      //   this.$message({
      //     message: '服务器号不能为空',
      //     type: 'error'
      //   })
      //   return
      // }
      if (this.editAccountData.email === '') {
        this.$message({
          message: '通知邮箱不能为空',
          type: 'error'
        })
        return
      }
      const obj = {
        flag: this.editAccountData.flag,
        email: this.editAccountData.email,
        passages: this.psCheckList,
        accountId: this.editAccountData.id
      }
      http.postJson('/editAccount', obj, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.passerList = res.data
          res.data.forEach(element => {
            if (element.accountId === this.editAccountData.id) {
              this.psCheckList.push(element.id)
            }
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    deleteRow (a) {
      const obj = {
        accountId: this.accountList[a].id
      }
      http.post('/deleteAccount', obj, function (error) {
        console.info(error)
      }, localStorage.token).then(res => {
        if (res.status === 0) {
          this.$message(res.msg)
          this.getAccoutList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
