import axios from 'axios'
import qs from 'qs'
// const baseUrl = 'http://www.changlekaoyan.com/jiekou'
// const baseUrl = 'http://localhost:8080/jiekou'
const baseUrl = 'http://121.43.148.214/jiekou'
axios.defaults.baseURL = baseUrl
// 改变默认的请求发送的数据格式
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 这样的话，只返回业务成功的数据给调用方
// 其他失败的情况一律抛出异常，包含业务处理失败的情况
axios.interceptors.response.use(resp => {
  const result = resp.data
  return result
}, err => {
  return Promise.reject(new Error(
    {
      message: err.msg
    }
  ))
})
const get = (url, params, errorHandler, token) => {
  return new Promise(resolve => {
    const headers = {}
    if (token) {
      headers.token = token
    }
    axios.get(url, { params, headers }).then(res => resolve(res)).catch(err => errorHandler(err))
  })
}
const post = (url, params, errorHandler, token) => {
  return new Promise(resolve => {
    const headers = {}
    if (token) {
      headers.token = token
    }
    axios.post(url, qs.stringify(params), { headers }).then(res => resolve(res)).catch(err => errorHandler(err))
  })
}
const postJson = (url, params, errorHandler, token) => {
  return new Promise(resolve => {
    const headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers.token = token
    }
    axios.post(url, params, { headers }).then(res => resolve(res)).catch(err => errorHandler(err))
  })
}
const postFile = (url, params, errorHandler, token) => {
  return new Promise(resolve => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    if (token) {
      headers.token = token
    }
    axios.post(url, params, { headers }).then(res => resolve(res)).catch(err => errorHandler(err))
  })
}
export default { get, post, postJson, postFile }
